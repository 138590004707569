var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        {
          ref: "formExpeditionPrinter",
          attrs: { name: "formExpeditionPrinter" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t(
                          "Dados da impressora térmica da Expedição"
                        ),
                        "show-loading": _vm.loadingPrintList,
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "pay_box_expedition_printer_tab-expedition_thermal_printer",
                                  name: "expeditionThermalPrinter",
                                  type: "switch",
                                  instruction: _vm.$t(
                                    "Se habilitado, mostra campos para configurar a impressora da expedição."
                                  ),
                                  label: _vm.$t(
                                    "Possui Impressora de Expedição?"
                                  ),
                                },
                                model: {
                                  value: _vm.payBox.expeditionThermalPrinter,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox,
                                      "expeditionThermalPrinter",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.expeditionThermalPrinter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.hasExpeditionThermalPrinter
                        ? [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "12" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-auto_identify_conf",
                                        type: "switch",
                                        label: _vm.$t(
                                          "Identificar Automaticamente as Configurações"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .autoIdentifyConf,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "autoIdentifyConf",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.autoIdentifyConf",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("FormulateInput", {
                                      class: _vm.isAutoIdentifyConf
                                        ? ""
                                        : "required",
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-printer_port",
                                        name: "printerPortSelected",
                                        type: "vue-select",
                                        label: _vm.$t("Impressora térmica"),
                                        placeholder: _vm.$t(
                                          "Selecione a impressora"
                                        ),
                                        options: _vm.getOptionsPrinter,
                                        validation: _vm.isAutoIdentifyConf
                                          ? ""
                                          : "required",
                                      },
                                      on: { input: _vm.onSelectPrinterPort },
                                      model: {
                                        value:
                                          _vm.localFormData.printerPortSelected,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.localFormData,
                                            "printerPortSelected",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "localFormData.printerPortSelected",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("e-button", {
                                      attrs: {
                                        id: "list-printers-btn",
                                        variant: "outline-primary",
                                        text: _vm.$t("Listar impressoras"),
                                      },
                                      on: { click: _vm.getPrintersList },
                                    }),
                                  ],
                                  1
                                ),
                                _c("b-col", { attrs: { md: "12" } }, [
                                  _vm.listPrinterError
                                    ? _c("p", { staticClass: "h6 red" }, [
                                        _vm._v(
                                          " Erro ao listar impressoras: " +
                                            _vm._s(_vm.listPrinterError) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm.showNetworkPrinterField ||
                                _vm.showLocalPrinterField
                                  ? _c(
                                      "b-col",
                                      { attrs: { md: "12" } },
                                      [
                                        _vm.showLocalPrinterField
                                          ? _c("FormulateInput", {
                                              class: _vm.isAutoIdentifyConf
                                                ? ""
                                                : "required",
                                              attrs: {
                                                id: "pay_box_expedition_printer_tab-printer_port_text",
                                                name: "localPrinterPort",
                                                type: "text",
                                                label: _vm.$t(
                                                  "Caminho da impressora local"
                                                ),
                                                validation:
                                                  _vm.isAutoIdentifyConf
                                                    ? ""
                                                    : "required",
                                              },
                                              on: {
                                                blur: _vm.onBlurPrinterPort,
                                              },
                                              model: {
                                                value:
                                                  _vm.localFormData.printerPort,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.localFormData,
                                                    "printerPort",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "localFormData.printerPort",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.showNetworkPrinterField
                                          ? _c("FormulateInput", {
                                              class: _vm.isAutoIdentifyConf
                                                ? ""
                                                : "required",
                                              attrs: {
                                                id: "pay_box_expedition_printer_tab-printer_port_text",
                                                name: "localPrinterPort",
                                                type: "text",
                                                label: _vm.$t("Endereço IP"),
                                                validation:
                                                  (_vm.isAutoIdentifyConf
                                                    ? ""
                                                    : "required|") +
                                                  "ipAddress",
                                              },
                                              on: {
                                                blur: _vm.onBlurPrinterPort,
                                              },
                                              model: {
                                                value:
                                                  _vm.localFormData.printerPort,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.localFormData,
                                                    "printerPort",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "localFormData.printerPort",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "8" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-printer_model",
                                        type: "vue-select",
                                        label: _vm.$t("Modelo"),
                                        placeholder:
                                          _vm.$t("Selecione o modelo"),
                                        options: _vm.optionsPrinterModel(),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .model,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "model",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.model",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-printer_page_code",
                                        type: "vue-select",
                                        label: _vm.$t("Código de página"),
                                        placeholder: _vm.$t(
                                          "Selecione o código de página"
                                        ),
                                        options: _vm.optionsPrinterPageCode(),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .pageCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "pageCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.pageCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-lines_between_coupons",
                                        type: "number",
                                        label: _vm.$t("Linhas entre cupons"),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .linesBetweenCoupons,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "linesBetweenCoupons",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.linesBetweenCoupons",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "3" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-space_between_lines",
                                        type: "number",
                                        label: _vm.$t("Linhas entre linhas"),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .spaceBetweenLines,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "spaceBetweenLines",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.spaceBetweenLines",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-columns_normal_font",
                                        type: "number",
                                        label: _vm.$t(
                                          "Quantidade de colunas para fonte normal"
                                        ),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .columnsNormalFont,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "columnsNormalFont",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.columnsNormalFont",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-verify_printer",
                                        type: "switch",
                                        label: _vm.$t("Verificar impressora"),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .verifyPrinter,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "verifyPrinter",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.verifyPrinter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "6" } },
                                  [
                                    _c("FormulateInput", {
                                      attrs: {
                                        id: "pay_box_expedition_printer_tab-control_port",
                                        type: "switch",
                                        label: _vm.$t("Controle de porta"),
                                      },
                                      model: {
                                        value:
                                          _vm.payBox.thermalPrinterExpedition
                                            .controlPort,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.payBox.thermalPrinterExpedition,
                                            "controlPort",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "payBox.thermalPrinterExpedition.controlPort",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.hasExpeditionThermalPrinter
                ? _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "no-actions": "",
                            title: _vm.$t(
                              "Teste impressora térmica da Expedição"
                            ),
                          },
                        },
                        [
                          _c("thermal-printer", {
                            attrs: {
                              "printer-type": "Expedition",
                              "show-cash-drawer-test": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }